<template src="./ReportHours.html"></template>

<script>
import { mapGetters } from 'vuex'
import {
  saveHoursReport,
  updateHoursReport,
  getUserComplianceCompanies
} from '../../../services'
import moment from 'moment'
import UsersCompliance from '../../../components/Forms/UsersCompliance/UsersCompliance'
import Companies from '../../../components/Forms/Companies/Companies'
import { OnlyAdmin, AdminCompliance } from '../../../utils/roles'

export default {
  name: 'ReportHours',
  components: { UsersCompliance, Companies },
  data () {
    return {
      valid: false,
      menu: false,
      infoHours: {
        activity: null,
        client: null,
        responsible: {
          id: '',
          fullname: ''
        },
        dateExecution: new Date().toISOString().substr(0, 10),
        timeDestined: 0,
        assignedHours: 0,
        documentAssociated: null,
        onSite: false,
        weekExecution: this.getWeekNumber(new Date()),
        plannedDate: new Date().toISOString().substr(0, 10),
        status: 'Ejecutado',
        planTask: false
      },
      tasks: [],
      menu1: false,
      update: false,
      updateDateExecution: new Date().toISOString().substr(0, 10),
      isEditing: false,
      responsibleName: '',
      responsibleId: '',
      companies: []
    }
  },
  computed: {
    ...mapGetters({
      user: 'getUser'
    }),
    OnlyAdmin,
    AdminCompliance
  },
  created () {
    if (this.$route.params.updateTask) {
      this.isUpdateTask()
      this.update = true
    }
    this.listCompanies()
  },
  mounted () {
    if (!this.$route.params.updateTask) {
      this.setResponsible()
    }
  },
  methods: {
    isUpdateTask () {
      if (this.$route.params.updateTask) {
        this.infoHours = { ...this.$route.params.updateTask }
        this.infoHours.weekExecution = this.getWeekNumber(new Date())
        this.infoHours.dateExecution = this.updateDateExecution
        this.infoHours.plannedDate = moment(this.infoHours.plannedDate).format(
          'YYYY-MM-DD'
        )
        this.responsibleName = `${this.$route.params.updateTask.userId.name} ${this.$route.params.updateTask.userId.lastName}`
        this.responsibleId = this.$route.params.updateTask.userId.id
        this.infoHours.planTask = this.$route.params.updateTask.planTask
        this.infoHours.status = 'Ejecutado'
        this.infoHours.client = this.$route.params.updateTask.companyId
      }
    },
    mapStatusToDB (status) {
      const statusMapping = {
        Planeado: 'PLANNED',
        Ejecutado: 'EXECUTED'
      }
      return statusMapping[status]
    },
    setResponsible () {
      if (this.user && this.user.name && this.user.lastName) {
        this.responsibleId = this.user.id
        this.responsibleName = `${this.user.name} ${this.user.lastName}`
      }
    },
    setResponsibleName () {
      if (this.infoHours.responsible && this.infoHours.responsible.fullname) {
        this.responsibleName = this.infoHours.responsible.fullname
      }
    },
    updateResponsible (value) {
      this.responsibleId = value.id
      this.setResponsibleName()
    },
    updateWeekExecution (date) {
      const parsedDate = new Date(date)
      this.infoHours.weekExecution = this.getWeekNumber(parsedDate)
      this.menu = false
    },

    getWeekNumber (d) {
      const date = new Date(d.getTime())
      const dayOfWeek = date.getUTCDay() === 0 ? 7 : date.getUTCDay()
      date.setUTCDate(date.getUTCDate() + 4 - dayOfWeek)
      const yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1))
      const firstThursday = new Date(
        yearStart.setUTCDate(
          yearStart.getUTCDate() + (4 - (yearStart.getUTCDay() || 7))
        )
      )
      const diffInDays = (date - firstThursday) / 86400000
      const weekNo = Math.ceil((diffInDays + 1) / 7)

      return weekNo
    },
    async saveAndUpdateReport () {
      try {
        if (!this.infoHours.activity) {
          this.$swal({
            text: 'Debe llenar el campo actividad',
            buttons: false,
            icon: 'warning',
            timer: 3000
          })
          return
        }
        if (
          !this.infoHours.dateExecution &&
          this.infoHours.status === 'Ejecutado'
        ) {
          this.$swal({
            text: 'Debe llenar el campo fecha de ejecución',
            buttons: false,
            icon: 'warning',
            timer: 3000
          })
          return
        }
        if (!this.infoHours.client) {
          this.$swal({
            text: 'Debe llenar el campo empresa',
            buttons: false,
            icon: 'warning',
            timer: 3000
          })
          return
        }
        if (
          !this.infoHours.timeDestined &&
          this.infoHours.status === 'Ejecutado'
        ) {
          this.$swal({
            text: 'Debe llenar el campo tiempo ejecutado',
            buttons: false,
            icon: 'warning',
            timer: 3000
          })
          return
        }
        if (!this.infoHours.plannedDate) {
          this.$swal({
            text: 'Debe llenar el campo fecha planeada',
            buttons: false,
            icon: 'warning',
            timer: 3000
          })
          return
        }
        if (!this.responsibleId) {
          this.$swal({
            text: 'Debe llenar el campo responsable',
            buttons: false,
            icon: 'warning',
            timer: 3000
          })
          return
        }

        const payload = {
          activity: this.infoHours.activity,
          dateExecution: this.infoHours.dateExecution,
          onSite: this.infoHours.onSite,
          timeDestined: Math.round(Number(this.infoHours.timeDestined) * 60),
          assignedHours: Math.round(Number(this.infoHours.assignedHours) * 60),
          userId: this.responsibleId,
          companyId: this.infoHours.client,
          weekExecution: this.infoHours.weekExecution,
          planTask: this.infoHours.planTask,
          plannedDate: new Date(this.infoHours.plannedDate),
          status: this.mapStatusToDB(this.infoHours.status)
        }

        if (this.infoHours.documentAssociated) {
          payload.documentAssociated = this.infoHours.documentAssociated
        }

        if (!this.update) {
          await saveHoursReport(payload)
        } else {
          payload.companyId = this.infoHours.client.id
          payload.id = this.infoHours.id
          await updateHoursReport(payload)
        }

        this.reset()
        this.$swal({
          text: this.update
            ? 'Tarea actualizada correctamente.'
            : 'Tarea creada correctamente.',
          buttons: false,
          icon: 'success',
          timer: 3000
        })
      } catch (error) {
        console.log('save and update report:', error)
      }
    },
    reset () {
      this.infoHours = {
        activity: null,
        responsible: `${this.user.name} ${this.user.lastName}`,
        dateExecution: new Date().toISOString().substr(0, 10),
        timeDestined: 0,
        assignedHours: 0,
        documentAssociated: null,
        onSite: false,
        weekExecution: this.getWeekNumber(new Date()),
        plannedDate: new Date().toISOString().substr(0, 10),
        status: 'Ejecutado'
      }
      this.responsibleName = ''
      this.update = false
    },
    toggleEdit () {
      this.isEditing = !this.isEditing
      if (!this.isEditing) {
        this.setResponsibleName()
      }
    },
    async listCompanies () {
      try {
        this.companies = await getUserComplianceCompanies()
        if (this.companies.length && this.infoHours.client) {
          this.updateCompany(this.infoHours.client)
        }
      } catch (err) {
        console.error('Error al listar las empresas:', err)
      }
    },
    updateCompany (company) {
      this.infoHours.client = company
    }
  }
}
</script>
